import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';
import SEO from '../../components/SEO';
import { ViewportBreakpoints } from '../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import Button from '@rotaready/frecl/build/Button';
import Margin from '@rotaready/frecl/build/Margin';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Section = styled.div`
  margin: 0 0 80px 0;
  text-align: center;
`;

const ButtonBox = styled.div`
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 30px;
    padding: 0;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const CheckmarkIconRaw = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'check-circle'],
})`
  color: ${({ theme: { colors } }) => colors.brand160};
  font-size: 64px;
`;

const CheckmarkIcon = () => (
  <Margin marginTop={40} marginBottom={40}>
    <CheckmarkIconRaw />
  </Margin>
);

const SignatureCompletePage = () => (
  <Body>
    <SEO title="Document Signed" />
    <ResponsiveContainer>
      <ContentWrapper>
        <Section>
          <CheckmarkIcon />
          <H3 uistyle="grey" text="You're all done! A copy will be available when everyone has signed." />
        </Section>

        <ButtonBox>
          <a href="https://visa.rotaready.com">
            <Button uistyle="primary" text="Login to Rotaready" size="lg" />
          </a>
          <Link to="/contact">
            <Button uistyle="primary" text="Get in touch" size="lg" ghost borderless />
          </Link>
        </ButtonBox>
      </ContentWrapper>
    </ResponsiveContainer>
  </Body>
);

export default SignatureCompletePage;
